// DeliverySystem.js
import React, { Component } from 'react';
import { Form, Field } from "react-final-form";
import { OnChange } from 'react-final-form-listeners';
import { InputGroup } from 'react-bootstrap';
import logo from './stacked-HPA-system-sm.png';

import { NumToZero } from './Library';


class DeliverySystem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resultPressLoss: this.props.calcData.deliveryPressLoss,
      resultResidenceTime: this.props.calcData.deliveryResidenceTime,
      resultHeightLoss: 0,
      resultTubeLoss: 0,
      resultFittjngLoss: 0,
      resultComponentLoss: 0
    };

    this.equivLoss = [
      {  // 1/4" ID equivilent length loss in feet
        fitTeeThrough: 0.5,
        fitTeeBranch: 1.5,
        fit90: 1.5,
        fit45: 0.7,
        fitCoupler: 0.5, 
        fitValve: 1, 
        fitSolenoid: 2, 
        fitFilter: 2.5,
        fitCheckvalve: 3.5
      },
      {  // ?? 1/4" ID
        fitTeeThrough: 0.8,
        fitTeeBranch: 2.4,
        fit90: 2.3,
        fit45: 0.3,
        fitCoupler: 0.3, 
        fitValve: 3, 
        fitSolenoid: 3, 
        fitFilter: 3.5,
        fitCheckvalve: 7.2
      },
      {  // ?? 1/4" ID
        fitTeeThrough: 0.8,
        fitTeeBranch: 2.4,
        fit90: 2.3,
        fit45: 0.3,
        fitCoupler: 0.3, 
        fitValve: 3, 
        fitSolenoid: 3, 
        fitFilter: 3.5,
        fitCheckvalve: 7.2
      }
    ];

  }

  componentDidMount = () => {
    // this.resultFlowHour = this.props.calcData.mistFlowHour;
    // this.resultFlowDay = this.props.calcData.mistFlowDay;
    this.updateDeliveryResults(this.props.calcData, this.props.calcData, this.props.calcData);
  }

  updateDeliveryResults = (thisValue, previous, allValues) => {
    // Hazen-Williams-Pressure-Loss-Calculator
    let FrictionCo = 140; //
    let LengthFT = NumToZero(allValues.deliveryTubeLength / 12);  // Inches to Feet
    let FlowGPM = NumToZero(this.props.calcData.mistFlowDelivery);  //Flow GPM
    let IDIn = NumToZero(allValues.deliveryTubeID);
    let TubeLoss = 10.46 * LengthFT * ( (FlowGPM/FrictionCo)**1.852) / ( (IDIn)**4.871);
    let rTubeLoss = NumToZero( (0.43 * TubeLoss).toFixed(3) );  // feet to inch PSI

    // Head Pressure Loss due to height
    let Height = NumToZero(allValues.deliveryTubeHeight / 12);  // inches to feet
    let rHeightLoss = NumToZero( (0.433 * Height).toFixed(3) );  // Height loss PSI

    // Residence Time
    let LengthIN = allValues.deliveryTubeLength;  // inches
    let Volume = 3.14159 * (IDIn**2) * LengthIN;  // in3
    // let Volume = 3.14159 * (2**2) * 12;  // in3
    let Gallons = Volume * 0.004329; // to Gallons in tubing length
    let rdTimeMinutes = Gallons / FlowGPM;  // residence time in minutes
    let rdTimeSeconds = NumToZero( (rdTimeMinutes * 60).toFixed(3) );  // residence time in seconds

    // Fittngs Pressure Los
    let fitTeeLoss = NumToZero(allValues.deliveryFittingTee * this.equivLoss[0].fitTeeBranch);  
    let fit90Loss = NumToZero(allValues.deliveryFitting90 * this.equivLoss[0].fit90);  
    let fit45Loss = NumToZero(allValues.deliveryFitting45 * this.equivLoss[0].fit45);  
    let fitCouplerLoss = NumToZero(allValues.deliveryFittingCoupler * this.equivLoss[0].fitCoupler);  
    let fitValve = NumToZero(allValues.deliveryValve * this.equivLoss[0].fitValve);  
    let fitSolenoid = NumToZero(allValues.deliverySolenoid * this.equivLoss[0].fitSolenoid);  
    let fitFilter = NumToZero(allValues.deliveryFilter * this.equivLoss[0].fitFilter);
    let fitCheckvalve = NumToZero(allValues.deliveryCheckvalve * this.equivLoss[0].fitCheckvalve);  

    LengthFT = NumToZero(fitTeeLoss + fit90Loss + fit45Loss + fitCouplerLoss);
    let FittingLoss = 10.46 * LengthFT * ( (FlowGPM/FrictionCo)**1.852) / ( (IDIn)**4.871);
    FittingLoss = NumToZero( FittingLoss.toFixed(3) );
    
    LengthFT = NumToZero(fitValve + fitSolenoid + fitSolenoid + fitFilter + fitCheckvalve);
    let ComponentLoss = 10.46 * LengthFT * ( (FlowGPM/FrictionCo)**1.852) / ( (IDIn)**4.871);
    ComponentLoss = NumToZero( ComponentLoss.toFixed(3) );


    let rTotalLoss = NumToZero(rTubeLoss + FittingLoss + ComponentLoss + rHeightLoss).toFixed(3);

    // Set State Data Local Results
    this.setState({ 
      resultPressLoss: rTotalLoss,
      resultHeightLoss: rHeightLoss,
      resultTubeLoss: rTubeLoss,
      resultFittingLoss: FittingLoss,
      resultComponentLoss: ComponentLoss,
      resultResidenceTime: rdTimeSeconds
    });
 
    // Update Shared Data
    this.updateAppStateValues(allValues, rTotalLoss, rdTimeSeconds);
  }

  // Set State App Data Staorage - Shared by all
  updateAppStateValues = (allValues, rTotalLoss, rdTimeSeconds) => {
    this.props.setAppState({
      deliveryTubeID: NumToZero(allValues.deliveryTubeID),
      deliveryTubeLength: NumToZero(allValues.deliveryTubeLength),
      deliveryTubeHeight: NumToZero(allValues.deliveryTubeHeight),
      deliveryFittingTee: NumToZero(allValues.deliveryFittingTee),
      deliveryFitting90: NumToZero(allValues.deliveryFitting90),
      deliveryFitting45: NumToZero(allValues.deliveryFitting45),
      deliveryFittingCoupler: NumToZero(allValues.deliveryFittingCoupler),
      deliveryValve: NumToZero(allValues.deliveryValve),
      deliverySolenoid: NumToZero(allValues.deliverySolenoid),
      deliveryFilter: NumToZero(allValues.deliveryFilter),
      deliveryCheckvalve: NumToZero(allValues.deliveryCheckvalve),
      deliveryPressLoss: NumToZero(rTotalLoss),
      deliveryResidenceTime: NumToZero(rdTimeSeconds)
    });
  }
  updateMistingNonResults = (thisValue, previous, allValues) => {
    this.props.setAppState({
      mistNozzlePressurLow: NumToZero(allValues.nozzlePressurLow),
      mistNozzlePressurHigh: NumToZero(allValues.nozzlePressurHigh)
    });
  }

  onSubmit = async values => {
    window.alert(JSON.stringify(values, 0, 2));
  };

render() {
  return (
    <div className="CalcSection CalcDelivery">
      <div className="CalcTitle CalcDeliveryTitle">
          <h1>Delivery System</h1>
      </div>
      <div className="CalcArea">
        <div className="FormArea">
            <div className="div-1 SectionArea">
                <img src={logo} className="App-logo" alt="logo" />
            </div>
            <div className="div-2 SectionArea">
              <Form
                onSubmit={this.onSubmit}
                render={({ handleSubmit, values }) => {
                  return (
                  <form onSubmit={handleSubmit}>
                    <div className="FormArea">
                    <div className="CalcArea">
                    <h2>Tubing</h2>
                    <div>
                      <label>Inside Diameter and Length (inches)</label>
                      <InputGroup className="mb-3">
                          <Field className="InlineFields" name="deliveryTubeID" component="input" type="number" initialValue={this.props.calcData.deliveryTubeID} /> 
                          <InputGroup.Append>
                            <InputGroup.Text>ID</InputGroup.Text>
                          </InputGroup.Append>
                          <Field className="InlineFields" name="deliveryTubeLength" component="input" type="number" initialValue={this.props.calcData.deliveryTubeLength} /> 
                          <InputGroup.Append>
                            <InputGroup.Text>Length</InputGroup.Text>
                          </InputGroup.Append>
                      </InputGroup>
                      <label>Height Above Tank (inches)</label>
                      <InputGroup className="mb-3">
                        <Field name="deliveryTubeHeight" component="input" type="number" initialValue={this.props.calcData.deliveryTubeHeight} /> 
                        <InputGroup.Append>
                        <InputGroup.Text>Inches</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                    <h2>Fittings and Components</h2>
                    <div>
                      <label>Fittings</label>
                      <InputGroup className="mb-3">
                          <Field className="InlineFields" name="deliveryFittingTee" component="input" type="number" initialValue={this.props.calcData.deliveryFittingTee} /> 
                          <InputGroup.Append>
                            <InputGroup.Text>Tee</InputGroup.Text>
                          </InputGroup.Append>
                          <Field className="InlineFields" name="deliveryFitting90" component="input" type="number" initialValue={this.props.calcData.deliveryFitting90} /> 
                          <InputGroup.Append>
                            <InputGroup.Text>90&deg;</InputGroup.Text>
                          </InputGroup.Append>
                      </InputGroup>
                      <InputGroup className="mb-3">
                          <Field className="InlineFields" name="deliveryFitting45" component="input" type="number" initialValue={this.props.calcData.deliveryFitting45} /> 
                          <InputGroup.Append>
                            <InputGroup.Text>45&deg;</InputGroup.Text>
                          </InputGroup.Append>
                          <Field className="InlineFields" name="deliveryFittingCoupler" component="input" type="number" initialValue={this.props.calcData.deliveryFittingCoupler} /> 
                          <InputGroup.Append>
                            <InputGroup.Text>Coupler</InputGroup.Text>
                          </InputGroup.Append>
                      </InputGroup>
                      <label>Components</label>
                      <InputGroup className="mb-3">
                        <Field name="deliveryValve" component="input" type="number" initialValue={this.props.calcData.deliveryValve} /> 
                        <InputGroup.Append>
                        <InputGroup.Text>Valve</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Field name="deliverySolenoid" component="input" type="number" initialValue={this.props.calcData.deliverySolenoid} /> 
                        <InputGroup.Append>
                        <InputGroup.Text>Solenoid</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>                      
                      <InputGroup className="mb-3">
                        <Field name="deliveryFilter" component="input" type="number" initialValue={this.props.calcData.deliveryFilter} /> 
                        <InputGroup.Append>
                        <InputGroup.Text>Filter</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Field name="deliveryCheckvalve" component="input" type="number" initialValue={this.props.calcData.deliveryCheckvalve} /> 
                        <InputGroup.Append>
                        <InputGroup.Text>Check Valve</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                    </div>
                  </div>
                  <OnChange name="deliveryTubeID">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>
                  <OnChange name="deliveryTubeLength">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>
                  <OnChange name="deliveryTubeHeight">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>
                  <OnChange name="deliveryFittingTee">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>
                  <OnChange name="deliveryFitting90">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>                    
                  <OnChange name="deliveryFitting45">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>                    
                  <OnChange name="deliveryFittingCoupler">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>                  
                  <OnChange name="deliveryValve">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>                  
                  <OnChange name="deliverySolenoid">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>
                  <OnChange name="deliveryFilter">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>
                  <OnChange name="deliveryCheckvalve">
                    {(value, previous) => {
                      // do something
                      this.updateDeliveryResults(value, previous, values);
                    }}
                  </OnChange>
                  <pre>{JSON.stringify(values, 0, 2)}</pre>
                  </form>
                  );
                }}
              />
            </div>
            <div className="div-3 SectionArea">
              <h2>Pressure Loss Results</h2>
              <div>
              <div className="SideBySide">
                  <div>
                  <label>Total Loss</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput FieldWidth90">{isNaN(this.state.resultPressLoss) ? '---' : this.state.resultPressLoss} PSI</p>
                  </InputGroup>
                  </div>
                  <div>
                    <label>Nozzle Pressure</label>
                    <InputGroup className="mb-3">
                    <p className="ReadOnlyInput FieldWidth90">{ isNaN(this.state.resultPressLoss) ? '---' : (this.props.calcData.mistNozzlePressurHigh - this.state.resultPressLoss).toFixed(3) } PSI</p>
                    </InputGroup>
                  </div>
                </div>
                <div className="SideBySide">
                  <div>
                    <label>Height Loss</label>
                    <InputGroup className="mb-3">
                      <p className="ReadOnlyInput FieldWidth90">{isNaN(this.state.resultHeightLoss) ? '---' : this.state.resultHeightLoss} PSI</p>
                    </InputGroup>
                  </div>
                  <div>
                    <label>Tubing Loss</label>
                    <InputGroup className="mb-3">
                      <p className="ReadOnlyInput FieldWidth90">{isNaN(this.state.resultTubeLoss) ? '---' : this.state.resultTubeLoss} PSI</p>
                    </InputGroup>
                  </div>
                </div>
                <div className="SideBySide">
                  <div>
                    <label>Fittings Loss</label>
                    <InputGroup className="mb-3">
                      <p className="ReadOnlyInput FieldWidth90">{isNaN(this.state.resultFittingLoss) ? '---' : this.state.resultFittingLoss} PSI</p>
                    </InputGroup>
                  </div>
                  <div>
                    <label>Components Loss</label>
                    <InputGroup className="mb-3">
                      <p className="ReadOnlyInput FieldWidth90">{isNaN(this.state.resultComponentLoss) ? '---' : this.state.resultComponentLoss} PSI</p>
                    </InputGroup>
                  </div>
                </div>
                <h2>Residence Time</h2>
                <InputGroup className="mb-3">
                  <p className="ReadOnlyInput">{isNaN(this.state.resultResidenceTime) ? '---' : this.state.resultResidenceTime} Seconds</p>
                </InputGroup>
                <label>Delivery Flow</label>
                <InputGroup className="mb-3">
                  <p className="ReadOnlyInput">{isNaN(this.props.calcData.mistFlowDelivery) ? '---' : this.props.calcData.mistFlowDelivery} GPM</p>
                </InputGroup>
              </div>
              <pre>{JSON.stringify(this.state, 0, 2)}</pre>
            </div>
        </div>
      </div>                 
    </div>
  );
}
}

export default DeliverySystem;