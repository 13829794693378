// UnknownPage.js

import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import logo from './logo.svg';

class UnknownPage extends Component {
  render() {
    return (
        <header className="App-header">
            <h1>404 Page</h1>
            <img src={logo} className="App-logo" alt="logo" />
            <Button>test</Button>
            <p>
                Edit <code>src/App.js</code> and save to reload.
                Hello Lee
            </p>
            <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
            >
                Learn React
            </a>
        </header>
    );
  }
}

export default UnknownPage;

      