// Library.js
export const NumToZero = (X) => {
    //console.log('func called');
    X = X || 0; //if false set to 0
    X = Number(X);
    return X;
  }
  
export const FlowHourCalc = (thisValue, allValues) => {
    let c = Number(allValues.nozzleCount || 1);
    let x = thisValue * 60;
    let y = Number(allValues.nozzleON)/((Number(allValues.nozzleOFF) * 60) + Number(allValues.nozzleON) );
    let t = x * y * c;
    let r = t.toFixed(3);
    //this.props.setFunc('mistFlowHour', r);
    return (r);
}