// ChargingSystem.js
import React, { Component } from 'react';
import { Form, Field } from "react-final-form";
import { OnChange, OnBlur } from 'react-final-form-listeners';
import { InputGroup } from 'react-bootstrap';

import { NumToZero } from './Library';

import logo from './charging-system-components.png';

class ChargingSystem extends Component {
    constructor(props) {
      super(props);

      this.state = {
        chargeFlowDutyCycle: this.props.calcData.chargeFlowDutyCycle,
        chargeTimeDutyCycle: this.props.calcData.chargeTimeDutyCycle,
        chargePumpPowerHour: this.props.calcData.chargePumpPowerHour,
        chargePumpPowerDay: this.props.calcData.chargePumpPowerDay,
        chargePumpPowerCost: this.props.calcData.chargePumpPowerCost,
        chargeTankDrawdownCalc: this.props.calcData.chargeTankDrawdownCalc,
        chargeTankRechargeTime: this.props.calcData.chargeTankRechargeTime,
        chargeTankDemandTime: this.props.calcData.chargeTankDemandTime,
        chargeTankEfficiency: this.props.calcData.chargeTankEfficiency,
        chargePumpCyclesEvery: this.props.calcData.chargePumpCyclesEvery
      }
    }

    componentDidMount = () => {
      // this.resultFlowHour = this.props.calcData.mistFlowHour;
      // this.resultFlowDay = this.props.calcData.mistFlowDay;
      this.updateChargingResults(this.props.calcData, this.props.calcData, this.props.calcData);
    }

    componentWillUnmount = () => {
      // Store new calculated State Data Results
      this.props.setAppState({
        chargeFlowDutyCycle: this.state.chargeFlowDutyCycle,
        chargeTimeDutyCycle: this.state.chargeTimeDutyCycle,
        chargePumpPowerHour: this.state.chargePumpPowerHour,
        chargePumpPowerDay: this.state.chargePumpPowerDay,
        chargePumpPowerCost: this.state.chargePumpPowerCost,
        chargeTankDrawdownCalc: this.state.chargeTankDrawdownCalc,
        chargeTankRechargeTime: this.state.chargeTankRechargeTime,
        chargeTankDemandTime: this.state.chargeTankDemandTime,
        chargeTankEfficiency: this.state.chargeTankEfficiency,
        chargePumpCyclesEvery: this.state.chargePumpCyclesEvery
      });
    }

    updateChargingResults = (thisValue, previous, allValues) => {
      // Flow DutyCycle Hour Calc
      let FlowGPM = NumToZero(this.props.calcData.mistFlowHour) / 60;  //Actual Flow GPM
      let pumpVolts = NumToZero(allValues.chargePumpVolts);
      let pumpAmps = NumToZero(allValues.chargePumpAmps);
      let pumpflow = NumToZero(allValues.chargePumpFlow);
      let pumpWatts = pumpVolts * pumpAmps;
      let cutIN = NumToZero(allValues.chargePumpCutON);
      let cutOff = NumToZero(allValues.chargePumpCutOFF);
      let tankCapacity = NumToZero(allValues.chargeTankVolume);
      let chargeFlowDutyCycle = ( FlowGPM / pumpflow * 100 ).toFixed(3); // flow dutycycle %
      let drawdown =  NumToZero(((cutOff - cutIN) / cutOff) * tankCapacity).toFixed(3);
      allValues.chargeTankDrawdown = drawdown;
      let tankEfficiency = NumToZero(drawdown / FlowGPM).toFixed(0);
      let tankDemandTime = NumToZero(drawdown / FlowGPM).toFixed(3);
      let tankRechargeTime = NumToZero( drawdown / (pumpflow - FlowGPM) ).toFixed(3);
      let pumpCyclesEvery = ( Number(tankDemandTime)  + Number(tankRechargeTime) ).toFixed(3);
      allValues.chargePumpCyclesEvery = pumpCyclesEvery;
      let timeDutyCycle = (Number(tankRechargeTime) / pumpCyclesEvery * 100).toFixed(3);
      let pumpPowerHour = (pumpWatts * (timeDutyCycle / 100)).toFixed(3);  // chargePumpPowerHour
      let pumpPowerDay = (pumpPowerHour * 24).toFixed(3);
      // calc power cost per day
      let electCost = NumToZero(allValues.chargeElectricityCost) / 100; // convert to cents
      let pumpPowerCostDay = (pumpPowerHour / 1000 * electCost * 24);

      // Set new calculated State Data Results
      this.setState({ 
        chargeFlowDutyCycle: chargeFlowDutyCycle,
        chargeTimeDutyCycle: timeDutyCycle,
        chargePumpPowerHour: pumpPowerHour,
        chargePumpPowerDay: pumpPowerDay,
        chargePumpPowerCost: pumpPowerCostDay,
        chargeTankDrawdownCalc: drawdown,
        chargeTankRechargeTime: tankRechargeTime,
        chargeTankDemandTime: tankDemandTime,
        chargeTankEfficiency: tankEfficiency,
        chargePumpCyclesEvery: pumpCyclesEvery
      });

      //return ( FlowHourCalc( thisValue, allValues ) );
      this.updateAppStateValues(allValues);
    }

    // Set State App Data Staorage - Shared by all
    updateAppStateValues = (allValues) => {
      this.props.setAppState({
        chargePumpDischarge: NumToZero(allValues.chargePumpDischarge),
        chargePumpFlow: NumToZero(allValues.chargePumpFlow),
        chargePumpVolts: NumToZero(allValues.chargePumpVolts),
        chargePumpAmps: NumToZero(allValues.chargePumpAmps),
        chargeElectricityCost: NumToZero(allValues.chargeElectricityCost),
        chargePumpCutON: NumToZero(allValues.chargePumpCutON),
        chargePumpCutOFF: NumToZero(allValues.chargePumpCutOFF),
        chargeTankVolume: NumToZero(allValues.chargeTankVolume),
        chargeTankWorkPressure: NumToZero(allValues.chargeTankWorkPressure),
        chargeTankDrawdown: NumToZero(allValues.chargeTankDrawdown)

      });
    }
    updateMistingNonResults = (thisValue, previous, allValues) => {
      this.props.setAppState({
        mistNozzlePressurLow: NumToZero(allValues.nozzlePressurLow),
        mistNozzlePressurHigh: NumToZero(allValues.nozzlePressurHigh)
      });
    }

    onSubmit = async values => {
      window.alert(JSON.stringify(values, 0, 2));
    };

  render() {
    return (
      <div className="CalcSection CalcCharging">
        <div className="CalcTitle CalcChargingTitle">
            <h1>Charging System</h1>
        </div>
        <div className="CalcArea">
          <div className="FormArea">
              <div className="div-1 SectionArea">
                  <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className="div-2 SectionArea">
                <Form
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, values }) => {
                    return (
                    <form onSubmit={handleSubmit}>
                      <div className="FormArea">
                      <div className="CalcArea">
                      <h2>Pump Specs</h2>
                      <div>
                        <label>Discharge Pressure</label>
                        <InputGroup className="mb-3">
                          <Field name="chargePumpDischarge" component="input" type="number" initialValue={this.props.calcData.chargePumpDischarge} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>PSI</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Flow Rate</label>
                        <InputGroup className="mb-3">
                          <Field name="chargePumpFlow" component="input" type="number" initialValue={this.props.calcData.chargePumpFlow} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>GPM</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Pump Voltage</label>
                        <InputGroup className="mb-3">
                          <Field name="chargePumpVolts" component="input" type="number" initialValue={this.props.calcData.chargePumpVolts} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>VOLTS</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Pump Running Maximum AMPS</label>
                        <InputGroup className="mb-3">
                          <Field name="chargePumpAmps" component="input" type="number" initialValue={this.props.calcData.chargePumpAmps} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>AMPS</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Electricity Cost per Kilowatt-hour</label>
                        <InputGroup className="mb-3">
                          <Field name="chargeElectricityCost" component="input" type="number" initialValue={this.props.calcData.chargeElectricityCost} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>Cents</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                       </div>
                       <h2>Pump Pressure Switch</h2>
                      <div>
                        <label>Cut-In (Cut-On) Pressure</label>
                        <InputGroup className="mb-3">
                          <Field name="chargePumpCutON" component="input" type="number" initialValue={this.props.calcData.chargePumpCutON} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>PSI</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Cut-Out  (Cut-Off) Pressure</label>
                        <InputGroup className="mb-3">
                        <Field name="chargePumpCutOFF" component="input" type="number" initialValue={this.props.calcData.chargePumpCutOFF} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>PSI</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                      <h2>Tank Specs</h2>
                      <div>
                        <label>Storage Volume</label>
                        <InputGroup className="mb-3">
                          <Field name="chargeTankVolume" component="input" type="number" initialValue={this.props.calcData.chargeTankVolume} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>GALLONS</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Maximum Working Pressure</label>
                        <InputGroup className="mb-3">
                        <Field name="chargeTankWorkPressure" component="input" type="number" initialValue={this.props.calcData.chargeTankWorkPressure} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>PSI</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Drawdown Capacity</label>
                        <InputGroup className="mb-3">
                        <Field name="chargeTankDrawdown" component="input" type="number" initialValue={this.props.calcData.chargeTankDrawdown} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>GALLONS</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                      </div>
                    </div>
                    <OnChange name="chargePumpDischarge">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>
                    <OnChange name="chargePumpFlow">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>
                    <OnChange name="chargePumpVolts">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>
                    <OnChange name="chargePumpAmps">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange> 
                    <OnChange name="chargeElectricityCost">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>                    
                    <OnChange name="chargePumpCutON">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>                    
                    <OnChange name="chargePumpCutOFF">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>                    
                    <OnChange name="chargeTankVolume">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>   
                    <OnChange name="chargeTankWorkPressure">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>
                    <OnChange name="chargeTankDrawdown">
                      {(value, previous) => {
                        // do something
                        this.updateChargingResults(value, previous, values);
                      }}
                    </OnChange>
                    <pre>{JSON.stringify(values, 0, 2)}</pre>
                    </form>
                    );
                  }}
                />
              </div>
              <div className="div-3 SectionArea">
                <h2>Results</h2>
                <div>
                  <label>Flow Capacity (Duty Cycle)</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargeFlowDutyCycle) ? '---' : this.state.chargeFlowDutyCycle} %</p>
                  </InputGroup>
                  <label>Runtime Duty Cycle</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargeTimeDutyCycle) ? '---' : this.state.chargeTimeDutyCycle} %</p>
                  </InputGroup>
                  <label>Pump Power Usage Hour</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargePumpPowerHour) ? '---' : this.state.chargePumpPowerHour} WATTS</p>
                  </InputGroup>
                  <label>Pump Power Usage Day</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargePumpPowerDay) ? '---' : this.state.chargePumpPowerDay} WATTS</p>
                  </InputGroup>
                  <label>Pump Power Cost Day</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargePumpPowerCost) ? '---' : '$'+ (this.state.chargePumpPowerCost).toFixed(2)}</p>
                  </InputGroup>
                  <label>Pump Power Cost 30-Day</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargePumpPowerCost) ? '---' : '$'+ (this.state.chargePumpPowerCost *30).toFixed(2) }</p>
                  </InputGroup>
                  <label>Calculated Tank Drawdown</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargeTankDrawdownCalc) ? '---' : this.state.chargeTankDrawdownCalc} GALLONS</p>
                  </InputGroup>
                  <label>Drawdown recharge time (Run)</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargeTankRechargeTime) ? '---' : this.state.chargeTankRechargeTime} MINUTES</p>
                  </InputGroup>
                  <label>Drawdown demand time (Off)</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargeTankDemandTime) ? '---' : this.state.chargeTankDemandTime} MINUTES</p>
                  </InputGroup>
                  <label>Tank Efficiency (Poor &lt;5, Good &gt;10)</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargeTankEfficiency) ? '---' : this.state.chargeTankEfficiency} LEVEL</p>
                  </InputGroup>
                  <label>Pump will cycle every</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.chargePumpCyclesEvery) ? '---' : this.state.chargePumpCyclesEvery} MINUTES</p>
                  </InputGroup>
                  <label>Actual Delivery Flow</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.props.calcData.mistFlowHour) ? '---' : (this.props.calcData.mistFlowHour / 60).toFixed(3)} GPM</p>
                  </InputGroup>
                </div>
                <pre>{JSON.stringify(this.state, 0, 2)}</pre>
              </div>
          </div>
        </div>                 
      </div>
    );
  }
}

export default ChargingSystem;

      