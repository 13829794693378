// Home.js

import React, { Component } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Form, Field } from "react-final-form";
import { OnChange } from 'react-final-form-listeners';
import { InputGroup } from 'react-bootstrap';
import logo from './flowrate-diagram.jpg';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: 'From parent',
      loadData: 'Loading',
    }
  }

  onSubmit = async values => {
    // window.alert(JSON.stringify(values, 0, 2));
    // axios.get(`https://aeroponicscalculator.com/aero-calc-data/aero-calc-api.php?getData=Fittings`)
    //axios.get(`http://localhost/aero-calc001/aero-calc-api.php?getData=Fittings`) 
    // Optionally the request above could also be done as
    // axios.get('http://localhost/aero-calc001/aero-calc-api.php', {
    axios.get('https://aeroponicscalculator.com/aero-calc-data/aero-calc-api.php', {
        params: {
        email: values.email,
        password: values.password,
        getData: 'Fittings'
      }
    })
      .then(res => {
        console.log('Data:', res.data);

        const fittingData = res.data;
        // let objectData = { loadData: persons };
        this.props.setAppState( fittingData );
        console.log('Losses:', this.state.equivLoss);
      })
      .catch(function (error) {
        // handle error
        console.log('Data Load Error: ', error);
      })
  };

  render() {
    return (
        <header className="App-header">
            <h1>The Aeroponics Calculator</h1>
            <img src={logo} className="App-logo" alt="logo" />

            <div className={ this.props.calcData.showlogin + " d-flex p-2"}>
              <Form
                onSubmit={this.onSubmit}
                render={({ handleSubmit, values }) => {
                  return (
                  <form onSubmit={handleSubmit}>
                    <div className="FormArea gridcenter">
                    <div className="addtopmargin">
                      <InputGroup>
                        <Field
                          type="email"
                          label="email"
                          title="E-mail"
                          name="email"
                          component="input"
                          placeholder="Your Email"
                          required
                          autoFocus
                        />
                      </InputGroup>
                      <InputGroup>
                      <Field
                        component="input"
                        name="password"
                        type="password"
                        label="password"
                        title="Password"
                        placeholder="Password"
                        required
                      />
                      </InputGroup>
                      
                    <div className="addtopmargin">
                    <Container>
                      <Row className="justify-content-md-center">
                          <Button variant="primary" size="lg" type="submit">SIGN IN</Button>
                      </Row>
                    </Container>
                    <Container>
                      <Row className="justify-content-md-center">
                          <h6><a href="https://aeroponicsdiy.com/aeroponics-calculator-registration/">REGISTER here to use the Aeroponics Calculator</a></h6>
                      </Row>
                    </Container>
                    </div>

                    </div> 
                  </div>

                  </form>
                  );
                }}
              />
            </div>
            <h2>{this.props.calcData.loadData}</h2>
           
        </header>
    );
  }
}

export default Home;

      