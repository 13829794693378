// About.js

import React from "react";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-calculate";
import logoCharging from './charging-system-components.png';
import logoMisting from './plastic-nozzle.png';
import logoDelivery from './stacked-HPA-system-sm.png';




const fruitOptions = ["","apple", "banana", "grapes", "cherries"].map(f => (
  <option key={f} value={f}>
    {f}
  </option>
));

const calculator = createDecorator({
  field: 'morningTea',
  updates: {
    afternoonTea: function(morningTea, allValues) {
      return allValues.afternoonTea === morningTea ? undefined : allValues.afternoonTea;
    }
  }
})

const onSubmit = async values => {
  window.alert(JSON.stringify(values, 0, 2));
};

const About = () => (
  <div>

<div className="jumbotron">
    <div className="container">
      <h1 className="display-3">Hello, High Tech Gardeners!</h1>
      <p>This is a simple guide on how to use the Aeroponics Calculator. Even though the calculator is dynamic it's best to follow the order below while entering the information. It's recommended to run the app on a desktop. However, the app is responsive for mobile devices.</p>
      <p><a className="btn btn-primary btn-lg" href="#" role="button">Nothing &raquo;</a></p>
    </div>
  </div>

  <div class="container">
    <p>The Aeroponics Calculator is separated into pages labelled Misting, Delivery and Charging. Each page is separated into three sections: image section, data input parameters section and results section. Input your values in the input section and let the calculator do its thing in generating your results.</p>
    <p>Please note all of your entered data is not stored at this time. Your data will be lost ounce you close the app. If the Aeroponics Calculator is useful, a future feature will allow you to setup an account with my server for data storage.</p>
  </div>

  <div className="container marketing">

    <hr className="featurette-divider"/>

    <div className="row featurette">
      <div className="col-md-7">
        <h2 className="featurette-heading">1 Misting <span className="text-muted">Getting wet.</span></h2>
        <p className="lead">Start here first. Enter your nozzle flowrate here.</p>
      </div>
      <div className="col-md-5">
        <img src={logoMisting} className="App-logo" alt="logo" />
      </div>
    </div>

    <hr className="featurette-divider"/>

    <div className="row featurette">
      <div className="col-md-7 order-md-2">
        <h2 className="featurette-heading">2 Delivery <span className="text-muted">Let's travel.</span></h2>
        <p className="lead">Now address the delivery system with tube size and length.</p>
      </div>
      <div className="col-md-5 order-md-1">
        <img src={logoDelivery} className="App-logo" alt="logo" />
      </div>
    </div>

    <hr className="featurette-divider"/>

    <div className="row featurette">
      <div className="col-md-7">
        <h2 className="featurette-heading">3 Charging <span className="text-muted">It’ll blow your mind.</span></h2>
        <p className="lead">Lastly, apply your pump and tank specifications.</p>
      </div>
      <div className="col-md-5">
        <img src={logoCharging} className="App-logo" alt="logo" />
      </div>
    </div>

  </div>

  </div>
);

export default About;