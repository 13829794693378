// MistingSystem.js
import React, { Component } from 'react';
import { Form, Field } from "react-final-form";
import { OnChange, OnBlur } from 'react-final-form-listeners'
import { InputGroup } from 'react-bootstrap';

import { NumToZero } from './Library';

import logo from './plastic-nozzle.png';

class MistingSystem extends Component {
    constructor(props) {
      super(props);

      this.state = {
        resultFlowHour: this.props.calcData.mistFlowHour,
        resultFlowDay: this.props.calcData.mistFlowDay,
        resultFlowDelivery: this.props.calcData.mistFlowDelivery
      }
    }

    componentDidMount = () => {
      // this.resultFlowHour = this.props.calcData.mistFlowHour;
      // this.resultFlowDay = this.props.calcData.mistFlowDay;
      this.updateMistingResults(this.props.calcData, this.props.calcData, this.props.calcData);
    }

    updateMistingResults = (thisValue, previous, allValues) => {
      // FlowHour Calc
      let c = NumToZero(allValues.mistNozzleCount || 1);
      let x = allValues.mistNozzleFlow * 60;
      let y = NumToZero(allValues.mistNozzleON)/((NumToZero(allValues.mistNozzleOFF) * 60) + NumToZero(allValues.mistNozzleON) );
      let t = x * y * c;
      let rHour = t.toFixed(3);

      // FlowDay Calc
      x = allValues.mistNozzleFlow * 60 * 24;
      c = NumToZero(allValues.mistNozzleCount || 1);
      y = NumToZero(allValues.mistNozzleON)/((NumToZero(allValues.mistNozzleOFF) * 60) + NumToZero(allValues.mistNozzleON) );
      t = x * y * c;
      let rDay = t.toFixed(3);

      // FlowDelivery Calc
      x = NumToZero(allValues.mistNozzleFlow || 0);
      y = NumToZero(allValues.mistNozzleCount || 1);
      let rDelivery = (x * y).toFixed(3);

      // Set State Data Results
      this.setState({ 
        resultFlowHour: rHour,
        resultFlowDay: rDay,
        resultFlowDelivery: rDelivery
      });

      //return ( FlowHourCalc( thisValue, allValues ) );
      this.updateAppStateValues(allValues, rHour, rDay, rDelivery);
    }

    // Set State App Data Staorage - Shared by all
    updateAppStateValues = (allValues, rHour, rDay, rDelivery) => {
      this.props.setAppState({
        mistNozzleFlow: NumToZero(allValues.mistNozzleFlow),
        mistNozzleCount: NumToZero(allValues.mistNozzleCount),
        mistNozzleON: NumToZero(allValues.mistNozzleON),
        mistNozzleOFF: NumToZero(allValues.mistNozzleOFF),
        mistFlowDay: NumToZero(rDay),
        mistFlowHour: NumToZero(rHour),
        mistFlowDelivery: NumToZero(rDelivery)
      });
    }
    updateMistingNonResults = (thisValue, previous, allValues) => {
      this.props.setAppState({
        mistNozzlePressurLow: NumToZero(allValues.nozzlePressurLow),
        mistNozzlePressurHigh: NumToZero(allValues.nozzlePressurHigh)
      });
    }

    onSubmit = async values => {
      window.alert(JSON.stringify(values, 0, 2));
    };

  render() {
    return (
      <div className="CalcSection">
        <div className="CalcTitle">
            <h1>Misting System</h1>
        </div>
        <div className="CalcArea">
          <div className="FormArea">
              <div className="div-1 SectionArea">
                  <img src={logo} className="App-logo" alt="logo" />
              </div>
              <div className="div-2 SectionArea">
                <Form
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, values }) => {
                    return (
                    <form onSubmit={handleSubmit}>
                      <div className="FormArea">
                      <div className="CalcArea">
                      <h2>Nozzle</h2>
                      <div>
                        <label>Flow Rate (gallons per minute)</label>
                        <InputGroup className="mb-3">
                          <Field name="mistNozzleFlow" component="input" type="number" initialValue={this.props.calcData.mistNozzleFlow} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>GPM</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Working Pressure Range (PSI)</label>
                        <InputGroup className="mb-3">
                          <Field className="InlineFields" name="nozzlePressurLow" component="input" type="number" initialValue={this.props.calcData.mistNozzlePressurLow} /> 
                          <Field className="InlineFields" name="nozzlePressurHigh" component="input" type="number" initialValue={this.props.calcData.mistNozzlePressurHigh} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>PSI</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Nozzle Count</label>
                        <InputGroup className="mb-3">
                          <Field name="mistNozzleCount" component="input" type="number" initialValue={this.props.calcData.mistNozzleCount} /> 
                        </InputGroup>
                      </div>
                      <h2>Misting Interval</h2>
                      <div>
                        <label>Seconds ON</label>
                        <InputGroup className="mb-3">
                          <Field name="mistNozzleON" component="input" type="number" initialValue={this.props.calcData.mistNozzleON} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>Seconds</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        <label>Minutes OFF</label>
                        <InputGroup className="mb-3">
                          <Field name="mistNozzleOFF" component="input" type="number" initialValue={this.props.calcData.mistNozzleOFF} /> 
                          <InputGroup.Append>
                          <InputGroup.Text>Minutes</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                      </div>
                    </div>
                    <OnChange name="mistNozzleFlow">
                      {(value, previous) => {
                        // do something
                        this.updateMistingResults(value, previous, values);
                      }}
                    </OnChange>
                    <OnChange name="mistNozzleCount">
                      {(value, previous) => {
                        // do something
                        this.updateMistingResults(value, previous, values);
                      }}
                    </OnChange>
                    <OnChange name="mistNozzleON">
                      {(value, previous) => {
                        // do something
                        this.updateMistingResults(value, previous, values);
                      }}
                    </OnChange>
                    <OnChange name="mistNozzleOFF">
                      {(value, previous) => {
                        // do something
                        this.updateMistingResults(value, previous, values);
                      }}
                    </OnChange>                    
                    <OnChange name="nozzlePressurLow">
                      {(value, previous) => {
                        // do something
                        this.updateMistingNonResults(value, previous, values);
                      }}
                    </OnChange>                    
                    <OnChange name="nozzlePressurHigh">
                      {(value, previous) => {
                        // do something
                        this.updateMistingNonResults(value, previous, values);
                      }}
                    </OnChange>
                    <pre>{JSON.stringify(values, 0, 2)}</pre>
                    </form>
                    );
                  }}
                />
              </div>
              <div className="div-3 SectionArea">
                <h2>Flow Results</h2>
                <div>
                  <label>Accumulative Gallons Per Hour</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.resultFlowHour) ? '---' : this.state.resultFlowHour} GPH</p>
                  </InputGroup>
                  <label>Accumulative Gallons Per Day</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{isNaN(this.state.resultFlowDay) ? '---' : this.state.resultFlowDay} GPD</p>
                  </InputGroup>
                  <label>Delivery Flow</label>
                  <InputGroup className="mb-3">
                    <p className="ReadOnlyInput">{this.state.resultFlowDelivery} GPM</p>
                  </InputGroup>
                </div>
                <pre>{JSON.stringify(this.state, 0, 2)}</pre>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MistingSystem;

      