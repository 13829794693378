import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import { Nav, Navbar, Form, Button, FormControl } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './Home';
import About from './About';
import Report from './Report';
import UnknownPage from './UnknownPage';
import MistingSystem from './MistingSystem';
import DeliverySystem from './DeliverySystem';
import ChargingSystem from './ChargingSystem';
import { NumToZero } from './Library';
import Privacy from './Privacy';

class AeroponicsApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: 'From parent',
      loadData: 'Please Sign In',
      showlogin: 'showlogin',  // login class
      mistNozzleFlow: 0.025,
      mistNozzlePressurLow: 80,
      mistNozzlePressurHigh: 100,
      mistNozzleCount: 8,
      mistNozzleON: 5,
      mistNozzleOFF: 3,
      mistFlowDay: 0,
      mistFlowHour: 0,
      mistFlowDelivery: 0.03,
      deliveryTubeID: 0.142,
      deliveryTubeLength: 10,
      deliveryTubeHeight: 48,
      deliveryFittingTee: 1,
      deliveryFitting90: 1,
      deliveryFitting45: 0,
      deliveryFittingCoupler: 0,
      deliveryValve: 0,
      deliverySolenoid: 1,
      deliveryFilter: 1,
      deliveryCheckvalve: 0,
      deliveryPressLoss: 0,
      deliveryResidenceTime: 0,
      chargePumpDischarge: 100,
      chargePumpFlow: 0.6,
      chargePumpVolts: 24,
      chargePumpAmps: 2,
      chargeElectricityCost: 16.7,
      chargePumpCutON: 80,
      chargePumpCutOFF: 100,
      chargeTankVolume: 2,
      chargeTankWorkPressure: 0,
      chargeTankDrawdown: 0,
      chargeFlowDutyCycle: 0,
      chargeTimeDutyCycle: 0,
      chargePumpPowerHour: 0,
      chargePumpPowerDay: 0,
      chargePumpPowerCost: 0,
      chargeTankDrawdownCalc: 0,
      chargeTankRechargeTime: 0,
      chargeTankDemandTime: 0,
      chargeTankEfficiency: 0,
      chargePumpCyclesEvery: 0,
      equivLoss: [
        {  // 1/4" ID equivilent length loss in feet
          fitTeeThrough: 0,
          fitTeeBranch: 0,
          fit90: 0,
          fit45: 0,
          fitCoupler: 0, 
          fitValve: 0, 
          fitSolenoid: 0, 
          fitFilter: 0,
          fitCheckvalve: 0
        },
        {  // ?? 1/4" ID
          fitTeeThrough: 0.8,
          fitTeeBranch: 2.4,
          fit90: 2.3,
          fit45: 0.3,
          fitCoupler: 0.3, 
          fitValve: 3, 
          fitSolenoid: 3, 
          fitFilter: 3.5,
          fitCheckvalve: 7.2
        },
        {  // ?? 1/4" ID
          fitTeeThrough: 0.8,
          fitTeeBranch: 2.4,
          fit90: 2.3,
          fit45: 0.3,
          fitCoupler: 0.3, 
          fitValve: 3, 
          fitSolenoid: 3, 
          fitFilter: 3.5,
          fitCheckvalve: 7.2
        }
      ]
    }
  }
  callbackFunction = (valueName, valueData) => {
    this.setState({ [valueName]: valueData });
    console.log('Data Call: ', JSON.stringify(this.state, 0, 2));
  }

  callbackSetState = (stateObject) => {
    this.setState(stateObject);
    console.log('Data State: ', JSON.stringify(stateObject, 0, 2));
  }

  componentDidMount() {
        // Update some of the calculated values based on stored data
    // FlowDelivery Calc
    let rDelivery = (NumToZero(this.state.mistNozzleFlow || 0) * NumToZero(this.state.mistNozzleCount || 1)).toFixed(3);
    this.setState({ mistFlowDelivery: rDelivery });
    
    // Actual FlowHour Calc
    let c = NumToZero(this.state.mistNozzleCount || 1);
    let x = this.state.mistNozzleFlow * 60;
    let y = NumToZero(this.state.mistNozzleON)/((NumToZero(this.state.mistNozzleOFF) * 60) + NumToZero(this.state.mistNozzleON) );
    let t = x * y * c;
    let mistFlowHour = t.toFixed(3);
    this.setState({ mistFlowHour: mistFlowHour });
    
    /* 
    // axios.get(`https://jsonplaceholder.typicode.com/users`) 
    axios.get(`http://localhost/aero-calc001/aero-calc-api.php?getData=Fittings`) 
    // axios.get(`https://aeroponicscalculator.com/aero-calc-data/aero-calc-api.php?getData=Fittings`) 
      .then(res => {
        console.log('Data:', res.data);

        const fittingData = res.data;
        // let objectData = { loadData: persons };
        this.setState( fittingData );
        console.log('Losses:', this.state.equivLoss);
      })
      .catch(function (error) {
        // handle error
        console.log('Data Load Error: ', error);
      })

      */
  }
  
  render() {
  return (
    <Router>
    <div className="App">
    <Navbar fixed="top" expand="md" bg="dark" variant="dark">
      <Navbar.Brand>
        <Link className="navbrandTitlelink" to={'/'}><img
        alt=""
        src="/calculator-logo.png"
        width="30"
        height="30"
        className="d-inline-block align-top"
        />{' '}Aeroponics Calculator
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
        <Nav.Item>
            <Nav.Link>
              <NavLink className="navlink" to="/" exact>Home</NavLink>
            </Nav.Link>
          </Nav.Item>          
          
          {this.state.loadData === 'Ready' ? 
            <Nav.Item>
              <Nav.Link>
                <NavLink className="navlink" to="/misting-system" exact>Misting</NavLink>
              </Nav.Link>
            </Nav.Item>
          : '' }
            
          
          {this.state.loadData === 'Ready' ?
            <Nav.Item>
              <Nav.Link>
                <NavLink className="navlink" to="/delivery-system" exact>Delivery</NavLink>
              </Nav.Link>
            </Nav.Item>
          : '' }

          {this.state.loadData === 'Ready' ?
            <Nav.Item>
              <Nav.Link>
                <NavLink className="navlink" to="/charging-system" exact>Charging</NavLink>
              </Nav.Link>
            </Nav.Item>
          : '' }
            
          {this.state.loadData === 'Ready' ? 
            <Nav.Item>
              <Nav.Link>
                <NavLink className="navlink" to="/report" exact>Report</NavLink>
              </Nav.Link>
            </Nav.Item>
          : '' }              
            
          <Nav.Item>
            <Nav.Link>
              <NavLink className="navlink" to="/about" exact>About</NavLink>
            </Nav.Link>
          </Nav.Item> 
        </Nav> 
      </Navbar.Collapse>
    </Navbar>

    <Switch>
        <Route exact path='/' 
          render={(props) => <Home calcData={this.state} setFunc={this.callbackFunction} setAppState={this.callbackSetState} />}
        /> 
        <Route
          path='/misting-system'
          render={(props) => <MistingSystem calcData={this.state} setFunc={this.callbackFunction} setAppState={this.callbackSetState} />}
        />        
        <Route
          path='/delivery-system'
          render={(props) => <DeliverySystem calcData={this.state} setFunc={this.callbackFunction} setAppState={this.callbackSetState} />}
        />
        <Route
          path='/charging-system'
          render={(props) => <ChargingSystem calcData={this.state} setFunc={this.callbackFunction} setAppState={this.callbackSetState} />}
        />
        <Route 
          path='/report' component={Report} 
        />
        <Route 
          path='/about' component={About} 
        />
        <Route 
          path='/privacy' component={Privacy} 
        />
        <Route 
          component={UnknownPage} 
        />
    </Switch>
    </div>

    <hr class="featurette-divider"/>
    <footer class="container">
      <p class="float-right"><a href="#">Back to top</a></p>
      <p>&copy; 2020 aeroponicsDIY &middot; <Link to={'/privacy'}>Privacy Policy</Link></p>
    </footer>
    </Router>
  );
  }
}

export default AeroponicsApp;
